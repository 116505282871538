<template>
    <section class="main-page">
        <section class="page-section">
            <div class="container">
                <div class="grid  q-pa-md card nice-shadow-6">
                    <div class="col comp-grid">
                        <div class="">
                            <div class="flex align-items-center ">
                                <div class="mr-3">
                                    <Avatar size="large" class=" text-white bg-orange-500" icon="pi pi-th-large" />
                                </div>
                                <div>
                                    <div class="text-2xl font-bold">App Menu</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="page-section mt-4 mb-3">
            <div v-if="user.booking_status != 'Pending'" class="container mb-4">
                <div class="grid">
                    <div class="col-12 md:col-12 comp-grid">
                        <div :class="getColor(user.booking_status)" class="card">
                            <div class="font-bold text-2xl mb-3">
                                My Booking Status
                            </div>
                            <div class="flex align-items-center justify-content-between mb-3">
                                <div v-if="user.booking_status == 'Queued'">
                                    <div class="text-lg font-medium">
                                        Your booking for <b>{{ $utils.upper(user.booking) }} </b> was
                                        <b>Successful</b>.
                                        The confirmation process may take up to 3 days. We appreciate your patience.
                                        In case of any issues or questions regarding the payment process,
                                        please contact us for assistance.
                                    </div>
                                </div>
                                <div v-if="user.booking_status == 'Declined'">
                                    <div class="text-lg font-medium">
                                        Your booking for <b>{{ $utils.upper(user.booking) }} </b> was
                                        <b>Declined</b>.
                                        In case of any issues or questions regarding the payment process,
                                        please contact us for assistance.
                                    </div>
                                    <div class="mt-2" v-if="user.booking_comments">
                                        Admin Comments: {{ user.booking_comments }}
                                    </div>
                                </div>
                                <div v-if="user.booking_status == 'Approved'">
                                    <div class="text-lg font-medium">
                                        Your booking for <b>{{ $utils.upper(user.booking) }} </b> was
                                        <b>Approved</b>.
                                        To secure your booking, please click the payment instructions button below.
                                    </div>
                                    <div class="mt-2" v-if="user.booking_comments">
                                        Admin Comments: {{ user.booking_comments }}
                                    </div>
                                    <div class="mt-3">
                                        <Button
                                            @click="app.openPageDialog({ page: 'info/instructions', url: `/info/instructions/app`, persistent: true, closeBtn: true, pageData: item })"
                                            class="p-button-info" type="button" label="Payment Instructions" />
                                        <Button class="p-button-primary ml-2" type="button" label="Upload Receipt" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container">
                <AppMenu :items="navbarSideLeftItems" :badge="user.notifications" />
            </div>
        </section>

    </section>
</template>
<script setup>
    import { ref, reactive, onMounted } from 'vue';
    import AppMenu from "@/components/AppMenu.vue";
    import { useApp } from '@/composables/app.js';
    import { useAuth } from '@/composables/auth';
    import { ApiService } from '@/services/api'
    const props = defineProps({
        pageName: {
            type: String,
            default: 'home',
        },
        routeName: {
            type: String,
            default: 'home',
        },
        isSubPage: {
            type: Boolean,
            default: false,
        },
    });
    const app = useApp();
    const auth = useAuth();

    const defaultValues = {
        booking_status: "",
        booking: "",
        booking_comments: "",
        notifications: 0,
    };
    const user = reactive({ ...defaultValues });

    const navbarSideLeftItems = app.menus.navbarSideLeftItems.filter((menu) =>
        auth.canView(menu.to)
    );

    const pageReady = ref(true);

    function getColor(status) {
        if (status == 'Queued') {
            return 'bg-yellow-100'
        } else if (status == 'Approved') {
            return 'bg-blue-100'
        } else if (status == 'Allocated') {
            return 'bg-green-100'
        } else if (status == 'Declined') {
            return 'bg-pink-100'
        } else {
            return '';
        }
    }

    function refreshUserData() {
        Object.assign(user, {
            booking_status: auth.user.booking_status,
            booking: auth.user.booking,
            booking_comments: auth.user.booking_comments,
        })
        getUserData();
    }

    async function getUserData() {
        try {
            const url = `users/status`;
            const response = await ApiService.get(url);
            const record = response.data;
            Object.assign(user, {
                booking_status: record.booking_status,
                booking: record.booking,
                booking_comments: record.booking_comments,
                notifications: record.notifications,
            })
        }
        catch (err) {
            console.log(err)
        }
    }


    onMounted(() => {
        const pageTitle = process.env.VUE_APP_NAME;
        app.setPageTitle(props.routeName, pageTitle);
        refreshUserData()
    });

</script>
<style scoped></style>