<template>
	<div class="grid" v-if="items">
		<template v-for="(item,i) of items">
			<div v-if="visible(item)" class="col-12 md:col-3 comp-grid">
				<router-link v-if="item.to" :to="item.to">
					<!-- :style="`background:${$utils.randomLightColor()}`" -->
					<div class="card nice-shadow-7 text-center">
						<div class="mb-2" style="width: auto">
							<Avatar :icon="item.icon" size="large" class="bg-orange-100" />
							<Badge severity="danger" style="position: absolute; margin: -6px;" v-if="item.target && badge" :value="badge"></Badge>
						</div>
						<div class="font-medium text-xl font-black mb-2">{{item.label}}</div>
					</div>
				</router-link>
			</div>
		</template>
	</div>
</template>
<script>
	export default {
		name: 'appsubmenu',
		props: {
			items: Array,
			badge: [Number, String],
		},
		data() {
			return {}
		},
		methods: {
			visible(item) {
				return (typeof item.visible === 'function' ? item.visible() : item.visible !== false);
			}
		}
	}
</script>